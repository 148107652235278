var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],attrs:{"id":"app_nav"}},[_c('div',{staticClass:"app_link",attrs:{"exact":""}},[_c('router-link',{attrs:{"to":"/home"}},[_c('img',{staticClass:"img",attrs:{"src":'/home' === _vm.$route.path
            ? _vm.tabBarImgArr[0].selected
            : _vm.tabBarImgArr[0].normal,"alt":"职业档案"}}),_c('p',{class:'/home' === _vm.$route.path ? 'active': 'noactive'},[_vm._v("首页")])])],1),_c('div',{staticClass:"app_link"},[_c('router-link',{attrs:{"to":"/message"}},[_c('img',{staticClass:"img",attrs:{"src":'/message' === _vm.$route.path
            ? _vm.tabBarImgArr[1].selected
            : _vm.tabBarImgArr[1].normal,"alt":"职业档案"}}),_c('p',{class:'/message' === _vm.$route.path ? 'active': 'noactive'},[_vm._v("授权中心")])])],1),_c('div',{staticClass:"app_link"},[_c('router-link',{attrs:{"to":"/userResume"}},[_c('img',{staticClass:"img",attrs:{"src":'/userResume' === _vm.$route.path
            ? _vm.tabBarImgArr[2].selected
            : _vm.tabBarImgArr[2].normal,"alt":"职业档案"}}),_c('p',{class:'/userResume' === _vm.$route.path ? 'active': 'noactive'},[_vm._v("我的简历")])])],1),_c('div',{staticClass:"app_link"},[_c('router-link',{attrs:{"to":"/onlineTag"}},[_c('img',{staticClass:"img1",attrs:{"src":'/onlineTag' === _vm.$route.path
            ? _vm.tabBarImgArr[3].selected
            : _vm.tabBarImgArr[3].normal,"alt":"职业档案"}}),_c('p',{class:'/onlineTag' === _vm.$route.path ? 'active': 'noactive'},[_vm._v("技能云")])])],1),_c('div',{staticClass:"app_link"},[_c('router-link',{attrs:{"to":"/user"}},[_c('img',{staticClass:"img",attrs:{"src":'/user' === _vm.$route.path
            ? _vm.tabBarImgArr[4].selected
            : _vm.tabBarImgArr[4].normal,"alt":"我的"}}),_c('p',{class:'/user' === _vm.$route.path ? 'active': 'noactive'},[_vm._v("我的")])])],1),(this.$route.path == '/message' || this.$route.path == '/' )?_c('van-overlay',{staticStyle:{"z-index":"999999"},attrs:{"show":_vm.show},on:{"click":_vm.isShow}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"block"})])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }